import { navigate } from "gatsby";
import React, { useEffect } from "react";

import { Layout } from "../components/Layout";
import Seo from "../components/SEO";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <Layout>
      <Seo title="Lapa netika atrasta!" />
    </Layout>
  );
};

export default NotFoundPage;
